import React from 'react'
import ccbysa from '../img/cc-by-sa-88x31.png'

// Creative Commons By Attribution - Share Alike License
// Used primarily on blog

const Ccbysa = () => {
  return (
    <p>
      This page is licensed under a<br />
      <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">
        Creative Commons Attribution-ShareAlike 4.0 International License
      </a>
      .<br />
      <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">
        <img alt="Creative Commons License" src={ccbysa} />
      </a>
    </p>
  )
}

export default Ccbysa
